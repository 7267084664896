import React from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import { connect } from 'react-redux';
import { Login, PermissionDenied, userLogout } from 'bot-user-session';
import SiteSelect from './siteSelect';
import { Header } from 'unified-nav';


function PrivateRoute({ component: Component,isAuthenticated, authenticationError, onUserLogout, ...rest }) {
    if (!isAuthenticated && authenticationError) {
        return (
            <div>
                <Header showProfile={false} onSignOut={onUserLogout}/>
                <PermissionDenied />
            </div>
        );
    }

    if (!isAuthenticated && !authenticationError) {
        return(
            <Redirect
                to={{
                    pathname: "/login" ,
                    search:window.location.search,
                }}
            />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    );
}

class App extends React.Component {

    render() {
        const {isAuthenticated, authenticationError, onUserLogout} = this.props;
        let style = {};

        return (
            <BrowserRouter>
                <div id="container" style={style}>
                    <Switch>
                        <Route path="/login" exact={false} render={(props) => <Login {...props} error={authenticationError} title="Pypestream" /> }  />
                        <Route path="/activate/:token?" exact={false} render={(props) => <Login {...props} activate={true} title="Pypestream" /> }  />
                        <Route path="/recover/:token?" exact={false} render={(props) => <Login {...props} recover={true} title="Pypestream" /> }  />
                        <PrivateRoute path="/" exact={false} component={SiteSelect} isAuthenticated={isAuthenticated} authenticationError={authenticationError} onUserLogout={onUserLogout}/>
                        <Route render={() => <div>Not Found </div>}/>
                    </Switch>
                </div>
            </BrowserRouter>
        )
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.botUserSession.isAuthenticated,
        authenticationError: state.botUserSession.error !== null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUserLogout() {
        dispatch(userLogout())
    },
})

export default connect(mapStateToProps,mapDispatchToProps)(App);
