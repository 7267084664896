const config = {
    OktaAuthConfig: {
        url: window.config.PS_UI_GATEWAY_OKTA_URL,
        issuer: window.config.PS_UI_GATEWAY_OKTA_ISSUER,
        redirectUri: window.location.origin,
        clientId: window.config.PS_UI_GATEWAY_OKTA_CLIENT_ID,
        scope: ['openid', 'email', 'profile', 'pypestream_access'],
        tokenManager: {
            storage: 'sessionStorage',
        },
    },
}

export default config
