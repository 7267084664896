import '@babel/polyfill';
import React from 'react'
import ReactDOM from 'react-dom'
import {createStore, applyMiddleware, combineReducers} from 'redux'
import {botUserSessionReducer,authClientWrapper} from 'bot-user-session'
import {Provider} from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import App from './App';
import config from "./config";
import './index.css'

const OktaAuthConfig = config.OktaAuthConfig;
authClientWrapper.setAuthConfiguration(OktaAuthConfig);

const rootReducer = combineReducers({
    botUserSession: botUserSessionReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root')
)
