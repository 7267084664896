import React from "react";
import {
    BOT_MANAGER_ROLES, doesUserHasRole, PYPE_MANAGER_ROLES, PYPE_AGENT_ROLES,
} from 'bot-user-session';


class SiteSelect extends React.Component {

    render() {
        const hasBotManager = doesUserHasRole(BOT_MANAGER_ROLES);
        const hasPype = doesUserHasRole(PYPE_MANAGER_ROLES) || doesUserHasRole(PYPE_AGENT_ROLES);

        if(hasBotManager) {
            window.location = "/design-studio";
            return <></>;
        } else if(hasPype) {
            window.location = "/pype-manager";
            return <></>;
        } else {
            window.location = "/console";
            return <></>;
        }
    }
}

export default SiteSelect;
